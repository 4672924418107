import * as React from "react";
import { Box, Link, Text } from "@chakra-ui/react";

function ContactMe() {
  return (
    <Box d="flex" border="2px solid white" borderRadius="2rem" maxW="14rem" bgColor="brand2.200">
      <Box p="1rem">
        <Box maxW="12rem">
          <Text color="brand5.100">
            Contact me:{" "}
            <Link color="brand3.100" href="mailto:evgeniii.work@gmail.com">
              evgeniii.work@gmail.com
            </Link>
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactMe;
