import * as React from "react";
import { HStack, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

function Menu() {
  return (
    <HStack spacing={"1rem"}>
      <Link as={RouterLink} to="/" color="brand4.100" fontSize="lg">
        Home
      </Link>
      <Link
        as={RouterLink}
        to="/swp"
        color="brand4.100"
        fontSize="lg"
      >
        Web Portal
      </Link>
    </HStack>
  );
}

export default Menu;
