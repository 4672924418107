import * as React from "react";
import { Box } from "@chakra-ui/react";
import ContactMe from "./ContactMe.js";
import Menu from "./Menu.js";
import RouteLandingContent from "./RouteLandingContent.js";

function Content() {
  return (
    <Box flex="1" display="flex" flexDir={{ base: "column", xl: "row" }}>
      <Box flex={{base:"1", xl:"none"}} display={{ base: "block", xl: "none", "2xl": "block" }}>
        <Box display={{ base: "flex", xl: "none" }} flexDir={{base: "column", sm: "row"}} p="1rem 2rem">
          <ContactMe />
          <Box m={{base: "1rem 0", sm: "2rem"}}>
            <Menu />
          </Box>
        </Box>
      </Box>
      <Box
        flex="1"
        m={{ base: "0 2rem 1rem 2rem", xl: "1rem 1rem" }}
        display="flex"
        flexDir={"column"}
      >
        <Box display={{ base: "none", xl: "block" }} p="0 0 1rem 0">
          <Box maxW="20rem">
            <Menu />
          </Box>
        </Box>
        <Box
          bgColor="brand2.300"
          borderRadius="0.5rem"
          borderWidth="3px"
          borderColor="brand2.100"
        >
          <RouteLandingContent />
        </Box>
      </Box>

      <Box
        flex={{ base: 0, xl: "0 0 14rem" }}
        display={{ base: "none", xl: "block" }}
        p="1rem"
      >
        <ContactMe />
      </Box>
    </Box>
  );
}

export default Content;
