import * as React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function SimpleWebPortal(props) {
  return (
    <Box>
      <Heading size="3xl" color="brand3.100" m="1rem" textAlign="center">
        Simple Web Portal
      </Heading>
      <Box p={{ base: "1rem", md: "1rem 6rem" }}>
        <Text color="brand5.100">
          Generic web portal with JWT authorization using React JS and Node JS:{" "}
          <Link
            color="brand4.100"
            href="https://github.com/evgenii-work/simple-web-portal"
            isExternal
          >
            Simple Web Portal <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default SimpleWebPortal;
