import * as React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

function NewsSWPRelease() {
  return (
    <Box>
      <Heading
        pl={{ base: "1rem", md: "6rem" }}
        size="xl"
        color="brand3.100"
        textAlign="left"
      >
        Simple Web Portal released
      </Heading>
      <Text pl={{ base: "1rem", md: "6rem" }} color="brand3.100">
        17.04.2022
      </Text>
      <Box p={{ base: "1rem", md: "1rem 6rem" }}>
        <Text color="brand5.100">
          The first version of my generic web portal is officially released .
          SimpleWebPortal is a React JS app with Node.js backend,  JWT authorization, skins
          and multiple language support.
        </Text>
        <Link
          color="brand4.100"
          href="https://github.com/evgenii-work/simple-web-portal"
          isExternal
        >
          Simple Web Portal <ExternalLinkIcon mx="2px" />
        </Link>
      </Box>
    </Box>
  );
}

export default NewsSWPRelease;
