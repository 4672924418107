import * as React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

function Header() {
  return (
    <Box
      w="100%"
      h="42rem"
      bgImage="url('astronaut-1920.jpg')"
      bgPos="45% 34%"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Box w={{ base: "100%", xl: "80rem" }} h="100vh" m="auto">
        <Heading
          pt="10rem"
          size="4xl"
          color="brand4.100"
          ml={{ base: "10%", xl: "2rem" }}
        >
          Evgenii's Place
        </Heading>
        <Box
          mt="10rem"
          bgColor="brand2.200"
          borderRadius="0.5rem"
          borderWidth="1px"
          borderColor="brand2.100"
          ml={{ base: "10%", xl: "2rem" }}
          mr={{ base: "10%", md: "20%", lg: "40%", xl: "60%" }}
          p="1rem"
        >
          <Text color="brand5.100" fontSize="xl">
            My name is Evgenii. I'm a full stack web developer and system
            administrator
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
