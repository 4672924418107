import * as React from "react";
import { Route, Routes } from "react-router-dom";
import LandingContent from "./LandingContent.js";
import PrivacyPolicy from "./EndlessRoller/PrivacyPolicy.js";
import SimpleWebPortal from "./development/simple-web-portal.js";

function RouteLandingContent() {
  return (
    <Routes>
      <Route path="/" element={<LandingContent />} />
      <Route path="swp" element={<SimpleWebPortal />} />
      <Route path="endless_roller_privacy_policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default RouteLandingContent;
