import * as React from "react";
import { Box, Heading } from "@chakra-ui/react";
import NewsSWPRelease from "./news/SWP.js";

function LandingContent() {
  return (
    <Box>
      <Heading size="3xl" color="brand3.100" m="1rem" textAlign="center">
        News
      </Heading>
      <NewsSWPRelease />
    </Box>
  );
}

export default LandingContent;
