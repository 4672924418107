import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Main from "./Main.js";
import { BrowserRouter } from "react-router-dom";

function App({ Component }) {
  const theme = extendTheme({
    colors: {
      brand1: {
        100: "#2C3531",
      },
      brand2: {
        100: "#116466",
        200: "rgba(17, 100, 102, 0.6)",
        300: "rgba(17, 100, 102, 0.4)",
      },
      brand3: {
        100: "#D9B08C",
      },
      brand4: {
        100: "#FFCB9A",
      },
      brand5: {
        100: "#D1E8E2",
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
