import * as React from "react";
import {
  Box,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

function PrivacyPolicy() {
  return (
    <Box>
      <Heading size="xl" color="brand3.100" m="1rem" textAlign="center">
        Privacy Policy
      </Heading>
      <Box p={{ base: "1rem", md: "1rem 6rem" }}>
        <Text color="brand5.100">
          Evgenii Plavenchuk built the Endless Roller game as an Commercial
          game. This SERVICE is provided by Evgenii Plavenchuk at no cost and is
          intended for use as is.
        </Text>
        <br />
        <Text color="brand5.100">
          This page is used to inform visitors regarding my policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use my Service.
        </Text>
        <br />
        <Text color="brand5.100">
          If you choose to use my Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that I collect is used for providing and improving the
          Service. I will not use or share your information with anyone except
          as described in this Privacy Policy.
        </Text>
        <br />
        <Text color="brand5.100">
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at Endless Roller unless
          otherwise defined in this Privacy Policy.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Information Collection and Use
        </Heading>
        <Text color="brand5.100">
          For a better experience, while using our Service, I may require you to
          provide us with certain personally identifiable information, including
          but not limited to Facebook public profile data. The information that
          I request will be retained on your device and is not collected by me
          in any way.
        </Text>
        <br />
        <Text color="brand5.100">
          The game does use third party services that may collect information
          used to identify you.
        </Text>
        <br />
        <Text color="brand5.100">
          Link to privacy policy of third party service providers used by the
          game
        </Text>
        <br />
        <UnorderedList>
          <ListItem color="brand3.100">
            <Link
              color="brand3.100"
              href="https://www.google.com/policies/privacy/"
            >
              Google Play Services
            </Link>
          </ListItem>
        </UnorderedList>
        <br />
        <Heading size="md" color="brand3.100" m="1rem">
          Log Data
        </Heading>
        <Text color="brand5.100">
          I want to inform you that whenever you use my Service, in a case of an
          error in the game I collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address,
          device name, operating system version, the configuration of the game
          when utilizing my Service, the time and date of your use of the
          Service, and other statistics.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Cookies
        </Heading>
        <Text color="brand5.100">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </Text>
        <br />
        <Text color="brand5.100">
          This Service does not use these “cookies” explicitly. However, the
          game may use third party code and libraries that use “cookies” to
          collect information and improve their services. You have the option to
          either accept or refuse these cookies and know when a cookie is being
          sent to your device. If you choose to refuse our cookies, you may not
          be able to use some portions of this Service.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Service Providers
        </Heading>
        <Text color="brand5.100">
          I may employ third-party companies and individuals due to the
          following reasons:
        </Text>
        <br />
        <UnorderedList>
          <ListItem color="brand3.100">
            <Text color="brand5.100">To facilitate our Service;</Text>
          </ListItem>
          <ListItem color="brand3.100">
            <Text color="brand5.100">
              To provide the Service on our behalf;
            </Text>
          </ListItem>
          <ListItem color="brand3.100">
            <Text color="brand5.100">
              To perform Service-related services; or
            </Text>
          </ListItem>
          <ListItem color="brand3.100">
            <Text color="brand5.100">
              To assist us in analyzing how our Service is used.
            </Text>
          </ListItem>
        </UnorderedList>
        <Text color="brand5.100">
          I want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Security
        </Heading>
        <Text color="brand5.100">
          I value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and I cannot
          guarantee its absolute security.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Links to Other Sites
        </Heading>
        <Text color="brand5.100">
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by me. Therefore, I strongly advise
          you to review the Privacy Policy of these websites. I have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Children’s Privacy
        </Heading>
        <Text color="brand5.100">
          These Services do not address anyone under the age of 13. I do not
          knowingly collect personally identifiable information from children
          under 13. In the case I discover that a child under 13 has provided me
          with personal information, I immediately delete this from our servers.
          If you are a parent or guardian and you are aware that your child has
          provided us with personal information, please contact me so that I
          will be able to do necessary actions.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Changes to This Privacy Policy
        </Heading>
        <Text color="brand5.100">
          I may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. I will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately after they are posted on
          this page.
        </Text>
        <Heading size="md" color="brand3.100" m="1rem">
          Contact Us
        </Heading>
        <Text color="brand5.100">
          If you have any questions or suggestions about my Privacy Policy, do
          not hesitate to contact me at evgeniii.work@gmail.com.
        </Text>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
