import * as React from "react";
import { Box } from "@chakra-ui/react";
import Header from "./Header.js";
import Content from "./Content.js";

function Main() {
  return (
    <Box
      minHeight="100vh"
      bgImage="url('cyan-bg.jpg') "
      bgRepeat="no-repeat"
      bgSize="cover"
      display="flex"
      flexDir="column"
    >
      <Box width={{base: "100%", xl: "80%"}} m="0 auto">
        <Header />
        <Content />
      </Box>
    </Box>
  );
}

export default Main;
